<div class="page" [ngClass]="moduleClass" [class.nojs-theme]="!isJS" [attr.data-theme]="userSelectedTheme" id="page--home-container">
  <app-toast-message *ngIf="message!==''"></app-toast-message>
  <div class="page__header">
    <div role="complementary">
      <a class="u-button u-button--default skip-navigation" [href]="skipNavigationPath" (click)="onSkipNavigationClick($event)">{{skipNavigationLabel}}</a>
    </div>
    <app-header></app-header>
  </div>

  <div class="page__container" role="main" [attr.aria-label]="applicationRoleAriaLabel">
    <div class="page__container-sidebar" *ngIf="globalNavRequired">
      <app-global-navigation [showMenu]="showMenu" (cascadeOpened)="cascadeOpened($event)" (cascadeThemeOpened)="cascadeThemeOpened($event)"></app-global-navigation>
    </div>
    <div class="page__container-wrapper page__content" id="page__content" (click)="handleClickEvent()">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="page__footer" *ngIf="showFooter">
    <app-footer></app-footer>
  </div>
</div>
<!-- //notification alarm start here -->
<ng-container>
  <maced-dialog dialogId="Default Dialog" class="notification-alarm" *ngIf="isDialogOpen">
    <maced-dialog-header dialogHeaderId="Default Dialog Header" title="Get Notified!">
    </maced-dialog-header>
    <maced-dialog-content dialogContentId="Default Dialog Content">
      <p>Keep up to date on your work and be informed when you have been assigned something new</p>
    </maced-dialog-content>
    <maced-dialog-footer dialogFooterId="Default Dialog Footer">
    <maced-button buttonId="Default Dialog Close 1" color="secondary" size="small" label="No"  type="button"  (click)='showNotificationDialog(false)'></maced-button>         
    <maced-button buttonId="Default Dialog Close 2" color="primary" size="small" label="Yes" type="button"  (click)='notification()'></maced-button>
    </maced-dialog-footer>
  </maced-dialog>
</ng-container>
<!-- //notification alarm end here -->

<app-error-page [errorOptions]="errorPageOption" *ngIf="showOfflineMode"></app-error-page>

<div *ngIf="isUserLogIn" class="app-content-update">
  <app-content-update></app-content-update>
</div>

<div id="RCFoverlay">
  <div id="RCFdraggable"></div>
</div>

<div *ngIf="(isConfirmed && (updateType != -1)) && !this.data.isOfflineUser" class="course-update-dialog">
  <maced-dialog [dialogId]="'dialog1'">
    <maced-dialog-content>
      <h2 class="c-modal-content__title">{{dialogTitle}}</h2>
      <p class="c-modal-content__text" [innerHTML]="subTitle"></p>
    </maced-dialog-content>
    <maced-dialog-footer class="c-dialog__content-footer" *ngIf="updateType != 2">
      <maced-button *ngIf="updateType === 0" [buttonId]="btnIdOne" [label]="btnLabelOne" color="secondary" styleClass="u-button-normal"
        (click)="dialogClicked(false)">
      </maced-button>
      <maced-button [buttonId]="btnIdTwo" [label]="btnLabelTwo" color="primary" styleClass="u-button-normal"
        (click)="dialogClicked(true)">
      </maced-button>
    </maced-dialog-footer>
  </maced-dialog>
</div>
<app-loader role="alert"></app-loader>
<app-performance-popup *ngIf="showPoupupMsg" [showPoupupMsg]="showPoupupMsg" (snoozePopupFunction)="snoozeTime()" 
(isSnoozedTillLogout)="isSnoozed($event)"></app-performance-popup>
<app-data-processing-popup aria-live="polite" role="alert"></app-data-processing-popup>
<div class="sr-only" aria-live="polite">{{displayLiveSRonlyData}}</div>
<div class="video-conference-theme" *ngIf="isVcFreezBackground"></div>
<ng-container *ngIf="dialogProps.visible">
  <app-dialog [dialogProps]="dialogProps">
  </app-dialog>
</ng-container>

<ng-container *ngIf="showEmbeddedDialog">
  <app-embedded-link-viewer></app-embedded-link-viewer>
</ng-container>

<ng-container *ngIf="showNoInternetDialog">
  <app-no-internet-connection-dialog [noInternetMessage]="noInternetMessage" (cancel)="onCancel($event)" (tryAgain)="onTryAgain()"></app-no-internet-connection-dialog>
</ng-container>

<ng-container *ngIf="isLogoutScreen && !data.isUnAutorizedUserErr">
  <app-session-limit-reached [isLogout]="true"></app-session-limit-reached>
</ng-container>