import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiConstants } from './core/constant/api.constant';
import { Data } from './core/models/data';
import { AuthResolver } from './core/services/auth.resolver';
import { TokenResolver } from './core/services/token.resolver';
import { GlobalSettings } from './global.settings';
import { LoginComponent } from './login/login.component';
import { DataMigrationStatusComponent } from './shared/components/data-migration-status/data-migration-status.component';
import { AccessibilityService } from './core/services/accessibility.service';
import { SessionLimitReachedComponent } from './shared/components/session-limit-reached/session-limit-reached.component';

const routes: Routes = [
  {
    path: 'download',
    loadChildren: () => import('./features/pages/pages.module').then(m => m.PagesModule)
  },
  { path: 'logout', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'courses',
    loadChildren: () => import('./features/courses/courses.module').then(m => m.CoursesModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'courses/courselist/adminClass',
    loadChildren: () => import('./features/courses/courses.module').then(m => m.CoursesModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'school-work',
    loadChildren: () => import('./features/school-work/school-work.module').then(m => m.SchoolWorkModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'bookviewer/:id',
    loadChildren: () => import('./features/bookreader/bookreader.module').then(m => m.BookreaderModule),
    resolve: {
      gettoken: TokenResolver
    },
  },
  {
    path: 'bookviewerv/:id',
    loadChildren: () => import('./features/bookreader/bookreader.module').then(m => m.BookreaderModule),
    resolve: {
      gettoken: TokenResolver
    },
  },
  {
    path: 'homework-assessment',
    loadChildren: () => import('./features/homework/dashboard.module').then(m => m.DashboardModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'extra-practices',
    loadChildren: () => import('./features/extra-practices/extra-practices.module').then(m => m.ExtraPracticesModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'challenge',
    loadChildren: () => import('./features/challenge/challenge.module').then(m => m.ChallengeModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'homework-assessment-student',
    loadChildren: () => import('./features/homework-assessment-student/homework-assessment-student.module').then(m => m.HomeworkAssessmentStudentModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'data-migration',
    component: DataMigrationStatusComponent,
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'courses/courselist/help',
    loadChildren: () => import('./features/courses/courses.module').then(m => m.CoursesModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'course-builder',
    loadChildren: () => import('./features/course-builder/course-builder.module').then(m => m.CourseBuilderModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'offline-bookviewer',
    loadChildren: () => import('./features/offline-bookviewer/offline-bookviewer.module').then(m => m.OfflineBookviewerModule),
    resolve: {
      gettoken: TokenResolver
    }
  },
  {
    path: 'access-denied-page',
    component: SessionLimitReachedComponent, 
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private _data: Data,
    private accessebilityService: AccessibilityService
  ) {
    const config = this.router.config;
    config.push({
      path: 'homework-assessment/assessment/builder',
      loadChildren: () => import('./mee-platform-builder-wrapper.module').then(m => m.MeePlatformBuilderWrapperModule),
      resolve: {
        gettoken: TokenResolver
      },
      data: { data: this._data, accessebilityService:accessebilityService, GlobalSettings: GlobalSettings, ApiConstants: ApiConstants, builderType: 'assessment', environment: environment }
    });
    config.push({
      path: 'homework-assessment/homework/builder',
      loadChildren: () => import('./mee-platform-builder-wrapper.module').then(m => m.MeePlatformBuilderWrapperModule),
      resolve: {
        gettoken: TokenResolver
      },
      data: { data: this._data, accessebilityService:accessebilityService, GlobalSettings: GlobalSettings, ApiConstants: ApiConstants, builderType: 'homework', environment: environment }
    });
    config.push({
      path: 'course-builder',
      loadChildren: () => import('./mee-platform-builder-wrapper.module').then(m => m.MeePlatformBuilderWrapperModule),
      resolve: {
        gettoken: TokenResolver
      },
      data: { data: this._data, accessebilityService: accessebilityService, GlobalSettings: GlobalSettings, ApiConstants: ApiConstants, builderType: 'courseBuilder', environment: environment }
    });
    config.push({
      path: '',
      component: LoginComponent,
      children: [
        {
          path: ':OTT',
          component: LoginComponent,
          pathMatch: 'full',
        },
      ],
      resolve: {
        authtoken: AuthResolver
      },
    });
    config.push({
      path: '**', redirectTo: ''
    });
    this.router.resetConfig(config);
  }
}