import { Injectable, EventEmitter } from '@angular/core';
import { ICurrentBook } from './currentbook';
import { ACEToken, MEEToken } from './token';
import { ICourse } from './course';
import { ITocPreference } from './toc-preference';
import { ResourceDetails } from '../models/resource-details';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { UserInfo } from './user-info';
import { RCFManifestDetails } from './rcf-models/rcfManifestDetails';
import { HOTSPOT_PAGE_NAVIGATION_TYPE, LinkOrigin, ShowExitPopupFlags } from './enum';
import { DropdownOption } from '@maced/angular-components/lib/dropdown/dropdown.model';
import { CODE_TYPE } from 'src/app/features/settings/class-management/class-management.constant';
import { BookNavButtonConfig  } from './header.model';
const languageDataSrc = require('src/assets/language/meeLanguageTranslation_en.json');
const colorData = require('src/assets/category-color/colorcategory.json');
@Injectable({
  providedIn: 'root'
})
export class Data {
  public microServiceFlags: any = {};
  public pageNoArray: any []= [];
  public bodyClass: EventEmitter<any> = new EventEmitter();
  public isLoading: EventEmitter<boolean> = new EventEmitter();
  public isLoadingLgoin: EventEmitter<boolean> = new EventEmitter();
  public isLoadingKmli: EventEmitter<boolean> = new EventEmitter();
  public isLoadingRcf: EventEmitter<boolean> = new EventEmitter();
  public ispopupLoading: EventEmitter<boolean> = new EventEmitter();
  public ispopupLoadingSuccess: EventEmitter<boolean> = new EventEmitter();
  public activationCodeType: CODE_TYPE | null = null;
  public isDesktopBox:boolean=true;
  public storage: any;
  public firebaseDeviceToken:string='';
  public fbc:any;
  public isKMLIChecked: boolean = false;
  public selectedLanguage: any = 'en';
  public isBookmarksNotesSynced: boolean = false;
  public isLastPageSynced: boolean = false;
  public isCurriculumEreaderUser:string = "";
  public isUserLogIn: boolean = false;
  public isAuthenticated: boolean = false;
  public isHybridUser: boolean = false;
  public aceToken?: ACEToken;
  public meeToken?: MEEToken;
  public selectedCourse?: ICourse;
  public selectedBook?: ICurrentBook;
  public selectedLesson: any;
  public selectedUnit: any;
  public setRedisApiCall: boolean = true;
  public gradingAssessmentCompleted: boolean = false;
  public gradingCompleted: boolean = false;
  public tokenKey: any;
  public isTestGenerator?: boolean;
  public showUpdateContent: any[] = [];
  public firstTimeRCFcall: boolean = true;
  public isResourcePack?: boolean;
  public manifestVersionData = [];
  public selectedAttendeesList: any = [];
  public selectedCoursesList: any = [];
  public selectedAttendeesIds: any = [];
  public submittedAttendeesIds:any = [];
  public onResourceChange: EventEmitter<any> = new EventEmitter();
  public onUpdateContentshow: EventEmitter<any> = new EventEmitter();
  public updatePageNumberData: EventEmitter<any> = new EventEmitter(false);
  public updateCurrentPageNumber: Subject<any> = new Subject();
  public showBookMenu: boolean = false;
  public showWhiteboardMenu: boolean = false;
  public showHotspotMenu: boolean = false;
  public pageFrames?: any[];
  public FMCtoken :string='';
  public backAction: boolean = false;
  public selectedFrameId: string = '';
  public ismainMenuOpen: boolean = false;
  public ismainMenuMinimized: boolean = false;
  public isBookLibrarySelected: boolean = false;
  public courses?: ICourse[];
  public resourceDetails?: ResourceDetails;
  public deleteAPIFlag: boolean = true;
  public manifestVerisonData?: [];
  public rcfIconId: any;
  public userInfo: UserInfo = new UserInfo();
  public isUserOnline?: boolean;
  public homeworkDetails: any;
  public componentStoreData: any;
  public TocData: any;
  public unitTOCStoreData: any;
  public lessonTOCStoreData: any;
  public chaptersTOCStoreData: any;
  public particularHomeworkDetails: any;
  public classData: any;
  public externalZoomout: boolean = false;
  public globalRoutUrl: any;
  public RcfDataDetails!: RCFManifestDetails;
  public frameNavigation: BehaviorSubject<object> = new BehaviorSubject<object>({});
  public rcfIConsInserted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showFrameNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showNextFrameNavButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showPrevFrameNavButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadMenuconfigAfterLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ispopup: EventEmitter<any> = new EventEmitter();
  resetZonalZoom: EventEmitter<any> = new EventEmitter();
  public manifestDownloaded: EventEmitter<any> = new EventEmitter();
  public isBluePatchActiveForZonalZoom: EventEmitter<any> = new EventEmitter();
  public stepperEevnt: EventEmitter<any> = new EventEmitter();
  public pdfBook: EventEmitter<any> = new EventEmitter();
  public bookSelection: EventEmitter<any> = new EventEmitter();
  public pageLayout: EventEmitter<any> = new EventEmitter();
  public onShowBook: EventEmitter<any> = new EventEmitter();
  public onMainEvent: EventEmitter<any> = new EventEmitter();
  public onClassChange: EventEmitter<any> = new EventEmitter();
  public onCourseChange: EventEmitter<any> = new EventEmitter();
  public onNotificationCountChange: EventEmitter<any> = new EventEmitter();
  public onHomeworkNotificationCountChange: EventEmitter<any> = new EventEmitter();
  public onMessageCountChange: EventEmitter<any> = new EventEmitter();
  public onBookChange: EventEmitter<any> = new EventEmitter();
  public openBook: EventEmitter<any> = new EventEmitter();
  public openLesson: EventEmitter<any> = new EventEmitter();
  public openBookMenu: EventEmitter<any> = new EventEmitter();
  public openFirstTimeMenu: EventEmitter<boolean> = new EventEmitter();
  public hideBookMenu: EventEmitter<any> = new EventEmitter();
  public mainMenuClass: EventEmitter<any> = new EventEmitter();
  public menusize: EventEmitter<any> = new EventEmitter();
  public constructHotspotMenu: EventEmitter<any> = new EventEmitter();
  public openHotspotMenu: EventEmitter<any> = new EventEmitter();
  public closeHotsopt: EventEmitter<any> = new EventEmitter();
  public closeMainMenu: EventEmitter<any> = new EventEmitter();
  public hideHotspotPanel: EventEmitter<any> = new EventEmitter();
  public openNextHotspot: EventEmitter<any> = new EventEmitter();
  public openPrevHotspot: EventEmitter<any> = new EventEmitter();
  public zoneZoomedOut: EventEmitter<any> = new EventEmitter();
  public mainMenuEvent: EventEmitter<any> = new EventEmitter();
  public mainMenuMinimizedEvent: EventEmitter<any> = new EventEmitter();
  public updateCode: EventEmitter<any> = new EventEmitter();
  public toggleFourCorners: EventEmitter<any> = new EventEmitter();
  public loadMenuPosition: EventEmitter<any> = new EventEmitter();
  public resetPDFZoomlevel: EventEmitter<any> = new EventEmitter();
  public hotSpotClicked: EventEmitter<any> = new EventEmitter();
  public maximizeBookMenu: EventEmitter<any> = new EventEmitter();
  public mainMenuHeight: EventEmitter<any> = new EventEmitter();
  public inLoginPage: EventEmitter<any> = new EventEmitter();
  public isTOCSelected: EventEmitter<any> = new EventEmitter();
  public updateAvailable: EventEmitter<any> = new EventEmitter();
  public bookContentloaded: EventEmitter<any> = new EventEmitter();
  public userPreferenceChange: EventEmitter<any> = new EventEmitter();
  public isNavigationDrawerOpen: EventEmitter<any> = new EventEmitter();
  public showPredefinedArea: EventEmitter<any> = new EventEmitter();
  public closeMenuBar: EventEmitter<any> = new EventEmitter();
  public isShowGlobalMenu: EventEmitter<any>= new EventEmitter();
  public isShowLanguageMenuBar: EventEmitter<any>= new EventEmitter();
  public toggleStatus: number = 0;
  public showToggle: EventEmitter<any> = new EventEmitter();
  public activeIds?: string[];
  public calendarRouteFromCalendar?: boolean;
  public tocPreferenceObj: ITocPreference = new ITocPreference();
  public loginErrorMessage?: string;
  public currentApp?: string;
  public recipientCalledFrom?: string;
  public isPrevNextClick?: boolean;
  public isInNative: boolean = false;
  public machineIdentifier: string = '';
  public meeFirstName: string = '';
  public meeLastName: string = '';
  public role: string = '';
  public currentDeviceToken?: string;
  public tocUnits: any;
  public deviceRootPath: string | undefined;
  public backPage: boolean = false;
  public homeWorkId?: string;
  public rcfPlayerClose: EventEmitter<any> = new EventEmitter();
  public isBookView: boolean = false;
  public isBookViewEvent: EventEmitter<any> = new EventEmitter();
  public toggleNextButton: EventEmitter<boolean> = new EventEmitter();
  public togglePreviousButton: EventEmitter<boolean> = new EventEmitter();
  public ispdfReaderActive: boolean = false;
  public defaultLessonThumb?: string;
  public defaultUnitThumb?: string;
  public scheduleTokenRefresh: any;
  public setCoursesFromSetSubscription: EventEmitter<any> = new EventEmitter();
  public isMeePortalNavigation: boolean = false;
  public progressTrackerSelectedCourse?: ICourse;
  public completedActivitySet?: any[];
  public stickyHeaderData: any = [];
  public selectedCoursePrgressData: any;
  public isUpdateContentVisited?: boolean;
  public updateMyContent?: boolean;
  public updateComponents?: string[];
  public localUserPreferences: any;
  public toc: any;
  public spotOnData: any;
  public tocViewType: any;
  public contentUnitId?: string;
  public contentUnitIdMapping: any;
  public courseName!: string;
  public contentUnitID?: any = "";
  public courseLevel!: string;
  public resourceType?: string;
  public manifestType?: string;
  public TocManifestloaded: boolean = false;
  public backUrl?: string;
  public options?: string;
  public contents?: any;
  public previousUrl?: string;
  public isJoinClass: boolean = false;
  public showDefaultUserPreferenceScreen?: boolean;
  public clickedOnLessonTiming: any;
  public isClickedfromTOC: boolean = false;
  public isScreenNameEdited: boolean = false;
  public isNewUserPref: boolean = false;
  public cornerDisabled = new Subject<boolean>();
  public isWhiteboardMenuMinimized: boolean = false;
  public addWhiteboardActiveClass: EventEmitter<any> = new EventEmitter();
  public resetZoomEpub: EventEmitter<any> = new EventEmitter();
  public addMainMenuClass: EventEmitter<any> = new EventEmitter();
  public makeHotspotActivitySelected = new Subject<string>();
  public isUserSelectedThemeClass = new Subject<string>();
  public removeSelectedActiveClass = new Subject<void>();
  public isMenuToggleSet: BehaviorSubject<any> = new BehaviorSubject(false);
  public isZoomedInOnHotSpot?: boolean;
  public selectedHotspotActivity?: any[];
  public hotspotNextCtrl: any;
  public hotspotPrevCtrl: any;
  public triggerHotspotExit = new Subject<void>();
  public triggerHotspotPrevNext = new Subject<any>();
  public hotspotData: any;
  public rcfActivityList: any;
  public clickedFlag: boolean | undefined = false;
  public units: any;
  public toggleNext: EventEmitter<boolean> = new EventEmitter();
  public togglePrevious: EventEmitter<boolean> = new EventEmitter();
  public openNextHotspotZoom = new Subject();
  public openPrevHotspotZoom = new Subject();
  public flagMultipleTrigger: boolean = true;
  public isDisabledMenuButton = new Subject<boolean>();
  public bookPageItem = new EventEmitter<any>();
  public viewerHeight = null;
  public viewerWidth = null;
  public viewerInitialHeight: any = null;
  public viewerInitialWidth: any = null;
  public canvasData: any;
  public dvalue: any;
  public penHighligth: boolean = false;
  public notesArray?: any[];
  public currentNoteArray?: any[];
  public bookmarksArray?: any[];
  public rcfClosed = new Subject();
  public isDigitalBookMessageShowed: any;
  public downloadStatus: any;
  public nextDownloadData: any;
  public pageNavigateFlag: any;
  public goToPageNmber: string = "";
  public bookLayout: string = "";
  public spineItemIndex: number = 0;
  public spineItemPageNumber: string = "";
  public totalNumberOfPages: string = "";
  public quickLinkPageNumber: string = "";
  public matchlessonData: any = [];
  public matchFramesData: any = [];
  public pageIndex: number = 0;
  public pageNumber: any;
  public pageIndexCheckFlag: any = [];
  public lastCourses: any;
  public _readium: any = {};
  public ContentID: any = "";
  public studentDataForSelectedClassForSetHW: any = [];
  public encryptionContext: any[] = [];
  public onNotificationClick = new Subject<string>();
  public hotspotElement = new Subject();
  public singlePage: boolean = false;
  public doublePage: boolean = false;
  public bookViewerPageLayout: string = '';
  public isRedirectToDownloadManager: boolean = false;
  public loadingMsg1: string = "Please wait while we load your data.";
  public loadingMsg2: string = "This may take some time.";
  public titleTeacher: string = "Macmillan Education Teacher";
  public titleStudent: string = "Macmillan Education Student";
  public titleAlturaGreetings: string = "Welcome to Macmillan Education Altura";
  public titleAltura: string = "Macmillan Education Altura";
  public countryCode: string = 'N.A';
  public rcfListToggleStatus = new Subject();
  public zonalCoordinate: any;
  public reader: any;
  public internetStatus: boolean = true;
  public isInternetRestored: EventEmitter<any> = new EventEmitter();
  public IsEpubReader = false;
  public IsBookContent = false;
  public isEplanner = false;
  public rcfIconRcfActivitySets: any;
  public notesApiStatus: boolean = false;
  public activitiesWithUnit: any;
  public zoomLeft: any;
  public loginStatus = false;
  public iconRcfActivitydata: EventEmitter<any> = new EventEmitter();
  public lastLessonFlag = false;
  public isRcfListOpen: boolean = false;
  public eplannerUrl?: string;
  public userFooterItems: any = [];
  public rewardsUrl?: string;
  public assessmentsUrl?: string;
  public innerWidths: number = 760;
  public screenMaxWidth: number = 800;
  public zoomDefault: number = 100;
  public zoomMax: number = 400;
  public landscape: string = 'landscape';
  public portrait: string = 'portrait';
  public page: string = 'PAGE';
  public appAndBrowser: boolean = false;
  public isMobileView: boolean = false;
  public showGotoForMobile: boolean = false;
  public zoomInOutData = new Subject<any>();
  public callAllActivity: EventEmitter<boolean> = new EventEmitter();
  public searchData = new Subject<any>();
  public subscriptionId = new Subject<any>();
  public isCallLoadBook: boolean = true;
  public isQuickLinkOpen: boolean = false;
  public setInteractiveElFocus: boolean = true;
  public isQuickLink: EventEmitter<boolean> = new EventEmitter();
  public isSearchOpen: EventEmitter<boolean> = new EventEmitter();
  public isGlossaryOpen: EventEmitter<boolean> = new EventEmitter();
  public isQuickLinksPresent: EventEmitter<boolean> = new EventEmitter();
  public isQuickLinksActive: EventEmitter<boolean> = new EventEmitter();
  public isQuikLinsDataClicked: EventEmitter<boolean> = new EventEmitter();
  public notificationBadgeCount: EventEmitter<any> = new EventEmitter();
  public displayQuickLinkData: EventEmitter<any> = new EventEmitter();
  public sendQuickLinkData: EventEmitter<any> = new EventEmitter();
  public quickLinkPageJump: EventEmitter<any> = new EventEmitter();
  public refreshFirstLoad: EventEmitter<any> = new EventEmitter();
  public isRefreshFirstLoad: boolean = false;
  public isMobileViewForQuickLinkPD: boolean = true;
  public gradebook_remdial_Url?: string;
  public notification_redirect_Url?: string;
  public messageType: string = 'inbox';
  public replyaddRecipient: boolean = false;
  public singlechildData: any;
  public onChildChange: EventEmitter<any> = new EventEmitter();
  public multiplechildDropdown: any;
  public selectedClassForReports: any;
  public selectedClassNameForReports?: string;
  public parentDropdown: any;
  public hideClassSetuploader :boolean = true;
  public languageSwitched: string  = 'en';
  public activeTab: string;
  public showLoaderQ: string[] = [];
 
  //ACCESSIBILITY ON QUICKLINK
  public isQuickLinkButtonTabPressed: EventEmitter<boolean> = new EventEmitter();
  public isQuickLinksCloseButtonShiftTabPressed: EventEmitter<boolean> = new EventEmitter();
  public isQuickLinkTrigger:any=new BehaviorSubject(false);
  public isQuickLinkClose:any=false;
  epubDownloadInProgress: boolean = false;
  glossaryData: any;
  public constructor() { }
  //NAVIGATION OF QUICKLINKS

  //RETURN TO FUNCTIONALITY OF QUICKLINK
  public isReturnToButtonVisible: boolean = true;
  public isReturnToButtonVisibleEvent: EventEmitter<boolean> = new EventEmitter();

  //TO GET APPLICABLE RESOURCETYPES OF QUICKLINK
  public quicklink_applicable_ResourceTypes: string[] = new Array<string>();
  public selectUnitToBeDisplayedOnToc: EventEmitter<boolean> = new EventEmitter();

  public userPrefforEplanner = new Subject<string>();
  public routerforEplanner = new Subject<string>();
  public isMsgSent: boolean = false;
  public isSSORedirectionFlag: boolean = false;
  public isViaOTT: boolean = false;
  public isLoginCentralPage: boolean = false;
  public updateSelectedCourse: EventEmitter<any> = new EventEmitter();
  public updateSelectedBook: EventEmitter<any> = new EventEmitter();
  public zoomScreenUrl: EventEmitter<any> = new EventEmitter();
  public zoomMeetingMsg: EventEmitter<any> = new EventEmitter();
  public msgSubject?: string;
  public msgDescription?: string;
  public zoomAuthCode?: string;
  public zoomUserProfileData: EventEmitter<any> = new EventEmitter();


  public zoomMeetingMsgData: any;
  public classIdForStudenPT?: string;
  public zoomAccessToken?: string;
  public selectedClassIdForReports?: string;
  public messagesuccessDelete?: boolean;
  public messageSuccessTrash?: boolean;
  public zoomRefreshToken?: string;
  public replyMessageSubjectChange?: boolean;
  public paginationChangeEventObject: any = {};
  public ebookContentLoaded: EventEmitter<any> = new EventEmitter();
  public loginExceedMessageAcknowledge: EventEmitter<any> = new EventEmitter();
  public typeOfVideoCall: EventEmitter<string> = new EventEmitter();
  public freeZoom: boolean = false;
  public disableHomeworkTabs: any;
  public hideActivity: boolean = true;
  public userUploadFilesPath = '/useruploadedfiles/staticfiles';
  public studentProgressData: any = {};
  public fromdashboard: boolean = false;
  public isImgDownloaded: boolean = false;
  public fromhome: boolean = false;
  public onMessageDetailPage: EventEmitter<any> = new EventEmitter(false);
  public currentZoomLevel: number = 100;
  public institutionLogo?: string;
  public isInstitutionLogo: boolean = false;
  public videoCallType?: number;
  public subscriptionDetails?: ICourse[];
  public versionChecked: boolean = false;
  public meeUserId: string = '';
  public startDate?: string;
  public endDate?: string;
  public accessstartdate?: any;
  public accessenddate?: any;
  public activitySetData: any = [];
  public isEditMode: boolean = false;
  public dataEmitCheck: boolean = true;
  public accountId: string = '';
  public clearCacheData:any = false;
  public calendarEventTitle?: string;
  public calendarEventLocation?: string;
  public calendarEventColor?: string;
  public calendarEventDescription?: string;
  public calendarEventStartTime?: string;
  public calendarEventsEndTime?: string;
  public calendarEventStartDate: any;
  public calendarEventEndDate: any;
  public editedHomeworkStatus = 0;
  public deletedHomeworkStatus = 0;
  public deAssignHomework = 0;
  public calendarEventIsRecurring: boolean = false;
  public RR_rule: any;
  public calendarPageData = { CalendarEventId: '', Colorcode: '', Description: '', EndTime: '', Location: '', StartTime: '', title: '', Attendees: null, endDate: '', startDate: '', IsRecurring: false, rule: {}, EventAttendees: [] };
  public onCalendarNotification: EventEmitter<any> = new EventEmitter();
  public isEventDeleted: boolean = false;
  public gTreeData: any = '';
  public gAttendeesData: any = '';
  public gOrgAttendeesCount: any = '';
  public quickLinksCount: any = 0;
  public gEventId: any = '';
  public callgetQuicklinksRedis: any = '';
  public gResAttendListCount: any = [];
  public msTeamsMeetingDetails: any;
  public msTeamsMsg: EventEmitter<string> = new EventEmitter();
  public msTeamsRecurrenceString: any;
  public msTeamsrecurrenceEnddateOcuurences?: number;

  public popupTitle?: string;
  public recurrenceDefaultValues: any;
  public weeklyOccurrenceDates: any = [];
  public monthlyOccurrenceDates: any;

  public isHomeworkNotification: boolean = false;
  public messageText?: string;
  public isCalendarEventPopupDelete: boolean = false;
  public downloadedRCF: boolean = false;
  public isMacxAssess: EventEmitter<any> = new EventEmitter(false);
  public eventAttendiesData: any[] | undefined;
  public selectedCalenderEventId: string = "";
  public hasCalendarDeletePopupOpened: boolean = false;
  public isComingFromAddRecipient: boolean = false;
  public isEditAddRecipint: boolean = false;

  public isHomeworkNavigation: boolean = false;
  public sharableHomeworkId: string = "";
  public IsOnCalenderDetails: boolean = false;

  public startDateofCalendar?: string;
  public endDateOfCalendar?: string;
  public isRememberMeChecked: boolean = false;
  public isredirectedFromerrorPage: boolean = false;
  public isOpenKMLIPopup: boolean = false;
  public updateAvailableFlag: boolean = false;
  public callLessonExtra: boolean = true;
  public isRCFIconLoaded: boolean = true;
  public isRCFPlayerShowing: boolean = false;
  public isNotesDisplayed: boolean = false;

  public showParentDropDown: EventEmitter<any> = new EventEmitter(false);

  public isRedirectedFromNotification: boolean = false;
  public removeClassonMainMenu: EventEmitter<any> = new EventEmitter(false);
  public contentUpdateDailogue: EventEmitter<any> = new EventEmitter(false);
  public isNotesPersistenceStatus$=new EventEmitter(false);  
  public displayNotes: Subject<boolean> = new Subject<boolean>();
  public isDataThemeSet: Subject<any> = new Subject<any>();
  public globalHeaderData: EventEmitter<any> = new EventEmitter<any>();
  public headerBackQueryParams: EventEmitter<any> = new EventEmitter();
  public headerLinks: EventEmitter<any[]> = new EventEmitter(true);
  public headerAriaLabels: EventEmitter<any> = new EventEmitter(true);
  public canvasEnabled: EventEmitter<boolean> = new EventEmitter(false);
  public contentUnitIds: EventEmitter<string> = new EventEmitter();
  public tocManifestLoad: EventEmitter<boolean> = new EventEmitter();
  public spotOnDataEmit: EventEmitter<any> = new EventEmitter();
  public callLessonExtraActivity: EventEmitter<boolean> = new EventEmitter();

  // validation canvas data on navigation when canvas enabled.
  public canvasDataModified: EventEmitter<boolean> = new EventEmitter(false);
  public isCanvasDataLoaded: EventEmitter<boolean> = new EventEmitter(false);
  public showConfirmationDialog: EventEmitter<any> = new EventEmitter();
  public kmliResponseReceived: EventEmitter<boolean> = new EventEmitter(false);
  public hideKMLIDialog: EventEmitter<boolean> = new EventEmitter(true);
  public navigateToTab: EventEmitter<string> = new EventEmitter();
  public globalNavigationMenuList: EventEmitter<any> = new EventEmitter();
  public globalNavigationMenuListStatic:any=[];
  public myDownloadCurrentTab: EventEmitter<string> = new EventEmitter();
  public nextPrevQuickLinkHandler: EventEmitter<any> = new EventEmitter();
  public onContentDocumentLoadStart: EventEmitter<any> = new EventEmitter();
  public loadGlobalNavidation: EventEmitter<string> = new EventEmitter();

  public isManifestLoaded: boolean = false;

  // delete iwb anotation
  public getIwbData: any[] = [];
  public readerObject: any;

  //epub packageDoc

  public packageDoc: any;
  public languageJson: any = languageDataSrc;
  public menuToggleJson: any;
  public menuToggleEnvObject: any;
  public colorJson: any = colorData;
  public trackBookmarkUpdateIndex: number = 0;
  public currentBookmarks: any;
  public CUID: any;
  public lastAccessedCourseDetails: { id: string, contentUnitId: string } = { id: '', contentUnitId: '' };
  public deviceType: string | undefined;
  public deviceOSType: string | undefined;

  public isPrevClick: boolean = false;
  public isNextClick: boolean = false;
  public currentPageNoMB: any = 0;
  public currMobilePage: any = "";

  public isCurrentPageAvailable: boolean = false;
  public currentTabletIndex = 0;

  public isInternet: boolean = true;
  public isLoginIn: boolean = false;
  public dialogSubmitEvent: EventEmitter<any> = new EventEmitter();

  public isBackClickedFromCourseDetails: EventEmitter<boolean> = new EventEmitter(false);
  public homeworkData: any = { homeworkName: '', payLoadData: {} };
  public nextCompentData: any[] = [];
  public selectedSubscription!: any;
  public meeUserIdCourses: string = this.aceToken?.MEEUserId ? this.aceToken?.MEEUserId : this.meeUserId;
  public footerTitle: EventEmitter<any> = new EventEmitter();
  public footerSubtitle: EventEmitter<any> = new EventEmitter();
  public actualPageNumber: any;
  public setDataForReturnToQuicklink: any = [];
  public NoteLayerData = false;
  public isHomeworkChecked: boolean = false;
  public isAssessmentChecked: boolean = false;
  public showBackLessonData: any = "";
  public resetQuicklinkBackData: boolean = false;
  public homeworkCourses: any[] = [];
  public assessmentCourses: any[] = [];
  public framedZoomedOut: boolean = true;
  public hideheaderFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public lastZonalOperation: string = '';
  public zoomToggleStatus: boolean = false;
  public isNoteEnabled: boolean = false;
  public TOCcalled: boolean = false;
  public TOCidref: any;
  public TOCPage: any;
  public tocDataArray: any = [];
  public rcfDataArray: any = [];
  public bookmarknotesdata: any = [];
  public globalBackUrl: string = '';
  public isSearchBookSidePanelOpen: boolean = false;
  public isUGC: EventEmitter<boolean> = new EventEmitter(false);
  public vcBackground: EventEmitter<any> = new EventEmitter();
  public setEventForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public homeworkAssessRedisData: any;
  // EPC Variable
  public getLastActivityEvent: EventEmitter<any> = new EventEmitter();
  public rcfLinkOrigin: string = LinkOrigin.Hotspot;
  public onActivityStart: any;
  public onActivityBack: any;
  public rcfContentWebviewWindow: any;
  public isMultiActivityLaunched: boolean = false;
  public launchActivityRCFPlayer: EventEmitter<any> = new EventEmitter();
  public launchActivitySubscription!: Subscription;
  public loadTOCMenu: EventEmitter<string> = new EventEmitter();
// Notification
public firebaseNotificationData: EventEmitter<any> = new EventEmitter();


  public currentActivityID: string = "";

  //Grading Variable
  public RcfDataDetailsByContentId: any = {};
  public TocDataBySubscriptionId: any = {};
  public studentIdForGrading!: string;
  public gradingAttemptNumber!: any;
  public enableAdvanceLoading: boolean = false;
  public onOGFeedbackSubmit: EventEmitter<string> = new EventEmitter();
  public isHomeworkAssessmentGrading: string = '';
  public languageOptions:DropdownOption[]=[];
  public paperGradingResponse: ReplaySubject<string> = new ReplaySubject(1, 5000);
  public showCompleteGradingResponse: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public closeGradingResponse:BehaviorSubject<any> = new BehaviorSubject<any>({isGradingPageclosed:false,studentdId:''});
  public showExitDialog: BehaviorSubject<ShowExitPopupFlags> = new BehaviorSubject<ShowExitPopupFlags>({hasInputFieldEdited:false});
  // homeworkAssessment variable
  public reloadActiveAssessmentAfterControlledStart: EventEmitter<any> = new EventEmitter(false);
  public reloadActiveAssessmentAfterRcfCloseSubmit: EventEmitter<any> = new EventEmitter(false);
  public rcfPlayerFinalSubmit: EventEmitter<any> = new EventEmitter(false);
  public offlineDownload: EventEmitter<any> = new EventEmitter(false);
  public userAssessmentId:number = 0;
  public reloadOnCloseOnce:boolean = true;

  public selectedCourseEntityIdFromDropDown: string = '';
  public selectedCourseBundleIdFromDropDown: string = '';
  public isNavigationFromAssessment: boolean = true;
  public dropDownCourseChanged: boolean = false;
  public studentIdsArray: any = [];

  public srDisplayData  =  new EventEmitter();
  public openRewardsModel:boolean = false;
  public pendoCountryCode:string = 'N.A';
  public isMigratedUserGlobal: boolean = false;
  public isCoverImageDownloadedGlobal: boolean = false;
  public organizationIDUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public globalQuerypath :any = {};
  public activeViewDetailButtonRCF :any;
  public internetSpeed: number;
  public internetSpeedUnits: string;
  public cpuUsage: number;
  public displayInternetMessage: string = '';
  public displayCPUMessage: string = '';
  public displayInternetMessageSubtext: string;
  public displayCPUMessageSubtext: string;
  public displayBothMessageSubtext: string;
  public displayBothMessage: string = '';
  public internetSpeedThreshold: string;	
  public isSnoozedTillLogout: boolean = false;
  public stopSnoozeTime : boolean = true;
  public enablePerformanceMeter: boolean;	
  public snoozeTimeInMin: any;
  public cpuUsageThreshold: string ;	
  public internetSpeedCheckFileSize:number;

  // offline bookviewer variable
  public copyToUsb: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public courseAssignmentDateObj: any;
  public termsAndCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public themeList: any;
  public defaultTheme: any;
  public isOfflineUser: boolean = false;
  public offlineAccessStartDate: string = '';
  public offlineAccessEndDate: string = '';
  public offlineOverDate: boolean = false;
  public globalThemeSelected: any;
  public digitalResourceLinkData :BehaviorSubject<any> = new BehaviorSubject<any>({});
  public cbRCFCall:boolean = false;
  public cbCourseName:string = '';
  public userSelectedThemeId:any = '';
  public globalEmptyImgErrorTheme :BehaviorSubject<any> = new BehaviorSubject<any>('error_blue');
  public downloadPath:string = '';
  public showLoader:boolean = true;
  public offlineUserDefultLang = 'en';
  // book viewer variables -start here
  public hasExecuted: boolean = false;
  // book viewer variables -end here
  public isShowEmbeddedLinkViewer: BehaviorSubject<any> = new BehaviorSubject<any>({isShowEmbeddedLink:false,url:''})
  public userSelectedThemeClass:any = '';
  public cbEntityID:any = '';
  public isDigitalResourcesActive: boolean = false;
  public userSelectedThemeObj: any = '';
  public isShowNoInternet: BehaviorSubject<any> = new BehaviorSubject<any>({isShowNoInternetDialog:false, action:'', component:''});
  //rightside menu variable start
  public isQuicklinksDisabledInBrowser=false;
  public _quickLinkReady = new BehaviorSubject<boolean>(false);
  public quickLinkReady$ = this._quickLinkReady.asObservable();
  public closeMenubarSource = new Subject<void>();
  public closeMenubar$ = this.closeMenubarSource.asObservable();
  public activeSessionRight=new BehaviorSubject("");
  public rightSidePanelOpen=new BehaviorSubject(false);
  public quickNavBarClose=new BehaviorSubject(false);
  public previosFocusElement:any;
  public isFeatureVisible:any=false;
  public isTabClick=false;
  public isLessonExtraActvityRecentClose:boolean=false;
  public prevQuicklinkMatch=false;
  public assessmentFormat: any;
  //rightside menu variable close
  public globalURL = '';
  public checkTwoLevel = false;
  public assignmentId: string;
  public organisationName: string = '';
  public isExportBtn:any = '';
  public isSkipNavigationExist = false;
  public getHomeworkAsssessmentListApiCall : boolean  = false;
  public getAssessmentIndividualSummaryDetailsTempBody :any = '';
  public userShowAnswerConfiguration: boolean = true;
  public isSessionLimitReached = new BehaviorSubject(false);
  public isSessionContinue: boolean = false;
  public isLogoutTrigger = new BehaviorSubject<boolean>(false);
  public isSessionExpire: boolean = false;
  public ottKey: string = '';
  public isNavigationTrigger : BehaviorSubject<string> = new BehaviorSubject("");
  public isNavigateActivityHappen$ : BehaviorSubject<{ nextPage: boolean; prevPage: boolean }> = new BehaviorSubject({nextPage: false, prevPage: false}); 
  
  public fullscreenResizedView = new BehaviorSubject(false);
  public isfullScreen: boolean = false;
  public isGlossaryPannelOpen: boolean = false;
  public iwbModeBtn: boolean = false;
  public selectedComponentId: string = '';
  public saveTokenHappen: boolean = false;
  //book-navigation headers variable -start
  public bookNavButtonConfig: BookNavButtonConfig = {
    className: 'book-navigation',
    color: 'ghost',
    size: 'small',
    shape: 'square',
    type: 'button',
    previous: {
      icon: 'icon-chevron-left',
      id: 'previosButtonFullScreen',
      disabled: false,
      label: 'Go back to previous Page',
    },
    next: {
      icon: 'icon-chevron-right',
      id: 'nextButtonFullScreen',
      disabled: false,
      label: 'Go to next Page',
    },
  };
  public categoryName?: string = '';
//book-navigation headers variable  -end
  public isfullscreenAlertSet: boolean = false;
  public selectedSubsId :any ;
  public exemptEventParameterObject = {};
  public isUnAutorizedUserErr: boolean = false;

// Define a Map to store the full screen navigation state
 public fullScreenHotspotNavigationState = new Map<string, any>([
  ['showFrameNavigation', false],
  ['prevNavFrame', true],
  ['nextNavFrame', true],
  ['prevlabel',''],
  ['nextLabel','']
]);

public frameNavMap = new Map<string, { action: 'next' | 'prev', navFrame: string, requiresKey?: boolean }>([
  [HOTSPOT_PAGE_NAVIGATION_TYPE.NEXT_FRAME, { action: 'next', navFrame: 'nextNavFrame' }],
  [HOTSPOT_PAGE_NAVIGATION_TYPE.PREVIOUS_FRAME, { action: 'prev', navFrame: 'prevNavFrame' }],
  [HOTSPOT_PAGE_NAVIGATION_TYPE.KEYDOWN_NEXT_FRAME, { action: 'next', navFrame: 'nextNavFrame', requiresKey: true }],
  [HOTSPOT_PAGE_NAVIGATION_TYPE.KEYDOWN_PREVIOUS_FRAME, { action: 'prev', navFrame: 'prevNavFrame', requiresKey: true }]
]);
}

