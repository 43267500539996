<div *ngIf="!isLogout && !data.isUnAutorizedUserErr" class="session-modal">
    <maced-dialog dialogId="sessionLimitReachedDialog">
        <maced-dialog-content>
            <maced-error-state id="sessionLimitReachedId" [imageUrl]="sessionReachedErrorImg"
                [title]="sessionReachedTitle" [errorMessage]="warning" [ariaLabels]="ariaLabels">
                <ng-container ngProjectAs="[content-body]">
                    {{sessionReachedMessage}}
                </ng-container>
                <ng-container ngProjectAs="[content-footer]">
                    <maced-button *ngIf="!isNojs" buttonId="continueSessionId" class="c-error-state__button-continue"
                        color="primary" shape="square" [label]="continueLabel" (click)="closeDialog();"
                        ariaLabel="continue" name="continue" value="continue">
                    </maced-button>
                    <maced-button *ngIf="isNojs" buttonId="continueSessionId" class="c-error-state__button-continue"
                        color="primary" shape="square" [label]="continueLabel" type="link" url="/courses/courselist"  [queryParams]="{hideSessionLimitDialog: 'true'}"
                        ariaLabel="continue" name="continue" value="continue">
                    </maced-button>
                    <maced-button buttonId="cancelSessionId" class="c-error-state__button-close" color="secondary"
                        shape="square" [label]="closeLabel" url="/logout" ariaLabel="close" name="close" value="close"
                        type="link">
                    </maced-button>
                </ng-container>
            </maced-error-state>
        </maced-dialog-content>
    </maced-dialog>
</div>
<div *ngIf="isLogout && !data.isUnAutorizedUserErr" class="session-modal-logout">
    <maced-dialog dialogId="sessionLimitReachedDialog">
        <maced-dialog-content>
            <maced-error-state id="logoutSessionId" [imageUrl]="logoutErrorImg" [title]="logoutTitle"
                [errorMessage]="warning" [ariaLabels]="ariaLabels">
                <ng-container ngProjectAs="[content-body]">
                    {{logoutMessage}}
                </ng-container>
                <ng-container ngProjectAs="[content-footer]">
                    <maced-button buttonId="closeId" color="primary" shape="square" [label]="closeLabel" url="/logout"
                        ariaLabel="close" name="close" value="close" type="link">
                    </maced-button>
                </ng-container>
            </maced-error-state>
        </maced-dialog-content>
    </maced-dialog>
</div>
<div *ngIf="data.isUnAutorizedUserErr" class="session-modal-logout">
    <maced-dialog dialogId="sessionLimitReachedDialog">
        <maced-dialog-content>
            <maced-error-state id="logoutSessionId" [imageUrl]="logoutErrorImg" [title]="unauthorizedTitle"
                [errorMessage]="warning" [ariaLabels]="unAuthAriaLabels">
                <ng-container ngProjectAs="[content-body]">
                    {{unauthorizedMessage}}
                </ng-container>
                <ng-container ngProjectAs="[content-footer]">
                    <maced-button buttonId="closeId" color="primary" shape="square" [label]="closeLabel" url="/logout"
                        ariaLabel="close" name="close" value="close" type="link">
                    </maced-button>
                    <maced-button buttonId="contactUsId" color="primary" shape="square" [label]="contactUsLabel" [url]="contactUsUrlLink"
                        ariaLabel="contactUs" name="contactUs" value="contactUs" type="link">
                    </maced-button>
                </ng-container>
            </maced-error-state>
        </maced-dialog-content>
    </maced-dialog>
</div>
