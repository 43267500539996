import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, Renderer2, PLATFORM_ID, Inject, ViewEncapsulation, HostListener, ElementRef } from '@angular/core';
import { AriaLabels } from '@maced/angular-components/lib/global-header/global-header.model';
import { NavigationEnd, Router, ActivatedRoute, RoutesRecognized, NavigationStart, NavigationError  } from '@angular/router';
import { LanguageExtService } from 'src/app/services/language-ext.service';
import { HotspotService } from 'src/app/core/services/hot-spot.service';
import { Data } from 'src/app/core/models/data';
import { GlobalSettings } from 'src/app/global.settings';
import { ReadiumReaderService } from 'src/app/core/services/readium-reader.service';
import { CommonService } from 'src/app/core/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { CourseService } from 'src/app/core/services/course.service';
import { getdeviceType, GRADING_PAPER_ASSESSMENT_URL, LastZonalOperationState, PAGE_NAVIGATION_TYPE, ROLETYPE, SEARCHLABEL, smallScreenSize, UserAccessType , HOTSPOT_PAGE_NAVIGATION_TYPE , keyboardEventCode} from 'src/app/core/models/enum';
import { delay, EMPTY, filter, first, of, pairwise, Subject, Subscription, switchMap, takeUntil, takeWhile , combineLatest, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WhiteboardUtilityService } from 'src/app/core/services/whiteboard-utility.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { BookControls, SearchModule, Settings } from 'src/app/core/models/gtm-models/gtm';
import { SidepanelService } from 'src/app/side-panel/sidepanel.service';
import { ZoomControlService } from 'src/app/core/services/zoom-control.service';
import { CdnAssets } from 'src/app/core/models/constants';
import { BookMenuUtilityService } from 'src/app/core/services/book-menu-utility.service';
import { isPlatformBrowser } from '@angular/common';
import { DropdownOption } from '@maced/angular-components/lib/dropdown/dropdown.model';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CookieService } from 'ngx-cookie-service';
import { queryParams } from 'src/app/features/homework/homework-name-dialog/homework-name-dialog.mock';
import { GTMEvents, BookInteractions } from 'src/app/core/models/gtm-models/gtm';
import { Placement } from '@popperjs/core';
import { DeviceType } from 'ngx-device-detector';
declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  logoAltText = 'Macmillan Education Everywhere Platform';
  isShowCascadebar = false;
  buttonType = 'button' as string;
  buttonId = 'hamburgerIcon' as string;
  buttonValue = 'hamburger' as string;
  public fullScreenButtonId = 'fullScreenButton' as string;
  public screenLabel : string;
  public screenLabelTooltip : string;
  public selectedPlacement: Placement = 'bottom';
  public isMobile=false;
  public isDesktop=false;
  public isTablet=false;
  public isFrameNavigationOn = false;
  activeHamburger = false as boolean;
  isShowMenubar = false;
  isShowlanguageMenubar = false;
  message = "ShowZoomArea";
  toggleNgModel: boolean = false;
  public showtogglebutton: any = false;
  idHeader = 'globalHeaderWapper';
  idModelHeaderZoom = 'ModalHeaderZoom';
  idModelHeaderHomework = '';
  imageLogoPath: string = GlobalSettings.routePath + "/assets/default/images/logo-mee-black-mac.svg";
  imageLogoPathSmall: string = GlobalSettings.routePath + "/assets/default/images/logo.svg?ve=2";
  meeBaseURL: string = "https://myclasses.macmillaneducationeverywhere.com/MEE/";
  logoHyperLink: string = this.meeBaseURL + 'Index';
  headerlinks: { id: string, label: string, url: string, icon: string, position: string, color: string, queryParams: any }[] = [];
  links: any[] = [];
  ariaLabels: AriaLabels = {
    backButtonAriaLabel: "Back",
    closeButtonAriaLabel: "Close",
    hamburgerAriaLabel: "Menu",
    logoIcon: "Logo"
  };
  SearchButtonLabel = SEARCHLABEL.search;
  showLogo: boolean = true;
  searchButtonClick: boolean = false;
  backUrl: string = "";
  backQueryParams: any;
  currentUrl: string = "";
  title: string = "Welcome";
  subTitle: string = " ";
  showGlobalHeader: boolean = false;
  showHeaderDownloadPage: boolean = false;
  showHeaderPrelogin: boolean = false;
  hideCompleteHeader: boolean = false;
  moreMenuTitle: string = "More";
  isBookviewer: boolean = false;
  isHomework: boolean = false;
  isExtraPracticePage: boolean = false;
  isNotificationPage: boolean = false;
  isMobileBrowser: boolean = true;
  isChallengePage: boolean = false;
  isGradingPaperAssessmentPage: boolean = false;
  exitButtonLabel: string = '';
  isTaskListingPage: boolean = false;
  isSmallContentModal: boolean = true;
  isBrowserOrDesktop: boolean = false;
  isSearch: boolean = false;
  globalsearchHeader: boolean = true;
  isSearchPanelOpen: boolean = false;
  contentUnitId: string = "";
  logoShowHide: boolean = false;
  subscriptionId: string = '';
  toggleText: string = 'Show Zoom Areas';
  zoomAreaOnLabel: string = 'Show Zoom Areas on toggle button';
  zoomAreaOffLabel: string = 'Show Zoom Areas off toggle button';
  headerLabel: string = 'Download app';
  disableSearchButton: boolean = false;
  deviceType: string = '';
  isCanvasEnabled!: boolean;
  isCanvasDataModified!: boolean;
  siteUrl: string = environment.siteUrl;
  isMultiStepModal: boolean = false;
  backButtonDisabled: boolean = false;
  selectActivityPage: boolean = false;
  exitActivityUrl: string = '/homework-assessment/task-listing'
  exitEPCUrl: string = '';
  exitPaperGradingUrl = GRADING_PAPER_ASSESSMENT_URL;
  exitPaperGradingQueryParam: any = {exit: true};
  isToggleDisabled: boolean = false;
  isNotesModeOnSubscription!: Subscription;
  checkInternetConnect!: Subscription;
  canvasEnabledSubscription!: Subscription;
  assessmentDialogProps: any;
  isBrowserPlatform: boolean = false;
  routerBackSubscription!: Subscription;
  headerLabelChallenge: string = '';
  languageOptions: DropdownOption[] = this.data?.languageJson?.languageOptions ?? ['en'];
  selectedOptions: any = this.languageOptions[0];
  logoUrl = '/';
  logoUrlParams = {};
  logo = GlobalSettings.routePath +'/assets/default/images/logo-mee-black-mac.svg';
  smallLogo = GlobalSettings.routePath +'/assets/default/images/logo.svg?ve=3';
  subscriptionActive: boolean = true;
  headerDesktopView: boolean = false;
  isHeaderShowCascadebar = false;
  cascadeMenus: any[] = [];
  backText = "Back";
  selectedLanguage = "";
  tempTitle = '';
  tempSubTitle = '';
  hideHamMenu:boolean=false;
  isNoficationDropDown:boolean=false; 
  headerMobileDropDown:boolean=true;
  public isDisableNextBtn: boolean = false;
  public isDisablePrevBtn: boolean = false;
  languageDropdownLabel:string ='';
  cascadeMenuList: any  = [
    [
      { id: 'Languages', name: 'Languages', icon: 'icon-chevron-right', isContainSubMenu: true },
      { id: 'Download', name: 'Download app', url: './download' }

    ],
    this.data.languageOptions
  ];
  isLanguageSwitcherPage = false;
  queryParams: any;
  selectQuestionsQueryParams: queryParams =  { openDialog: false ,buttonId: '', isOpen: false};
  private destroySubscription = new Subject<void>();
  isOfflineBookViewerPage: boolean = false;
  exitCourseBundlePage: string = '';
  institutionLogo:any;
  isInstitutionLogo: boolean = false;
  isBrowser: boolean;
  isNewlangunageDownload:boolean=false;
  public eventData:any;
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public languageExtService: LanguageExtService,
    public router: Router, private courseService: CourseService,
    public data: Data,
    public _cd: ChangeDetectorRef,
    private readiumReaderService: ReadiumReaderService,
    public commonService: CommonService,
    public utilityService: UtilityService,
    public _bookMenuUtilityService: BookMenuUtilityService,
    public hotspotService: HotspotService,
    private ngZone: NgZone,
    private _whiteboardUtilityService: WhiteboardUtilityService,
    private gtmService: GtmService,
    public activatedRoute: ActivatedRoute,
    public renderer: Renderer2,
    public sidePanelService: SidepanelService,
    public zoomControlService: ZoomControlService,
    public cookieService: SsrCookieService,
    private elementRef: ElementRef,
    public _cookieService: CookieService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    } else {
      this.isBrowser = false;
    }
    if (GlobalSettings.isBrowser) {
      this.smallLogo = environment.assetsCdnUrl + CdnAssets.logoCdn;
      this.logo = environment.assetsCdnUrl + CdnAssets.blackLogoCdn;
      this.imageLogoPathSmall = environment.assetsCdnUrl + CdnAssets.logoCdn;
      this.imageLogoPath = environment.assetsCdnUrl + CdnAssets.blackLogoCdn;
    }
    const deviceType = this.commonService.getDeviceType();
    this.isDesktop = deviceType === DeviceType.Desktop;
    this.isMobile = deviceType === DeviceType.Mobile;
    this.isTablet = deviceType === DeviceType.Tablet;
    this.subscribeToHeaderData();
    this.data.searchData.subscribe((res: any) => {
      this.contentUnitId = res.val;
    });
    this.data.isDataThemeSet.subscribe((res)=>{
      if (res) {
        this.isInstitutionLogo = this.data.isInstitutionLogo;
        this.institutionLogo = this.data.institutionLogo;
        if(!isPlatformBrowser(this.platformId) && this.eventData){
          this.handleNavigationEnd(this.eventData);
        }
        this._cd.detectChanges();
      }
    });
    this.data.subscriptionId.subscribe((res: any) => {
      of('Observable To Update HTML').pipe(delay(500)).subscribe(() => {  
        const buttonToFocus = document.getElementById('exitEPCActivity');
        if (buttonToFocus) {
          buttonToFocus.focus();
        };
      })
      this.subscriptionId = res;
      this.exitEPCUrl = `/courses/course-details/${this.subscriptionId}`;
      if(this.queryParams['secb']){
        this.exitEPCUrl = `/course-builder/student-dashboard`;
        this.data.globalHeaderData.emit({headerLogoVisible:false, headerTitle:this.queryParams?.headerTitle, headerSubTitle:this.queryParams?.courseName, headerBackUrl:``})
          this.backQueryParams = { ...this.queryParams,subscriptionId: this.queryParams?.subscriptionId, contentUnitId: this.queryParams?.contentUnitId, menuHierarchy:"2" , buttonFocusId:this.queryParams?.buttonFocusId,courseName: this.queryParams?.courseName};
          of('Observable To Update HTML').pipe(delay(500)).subscribe(() => {  
            const buttonToFocus = document.getElementById('exitEPCActivity');
            if (buttonToFocus) {
              buttonToFocus.focus();
            };
          })
          this._cd.detectChanges();
      }
      if(this.queryParams['ecb']){
        this.exitEPCUrl = `/course-builder/dashboard`;
        this.data.globalHeaderData.emit({headerLogoVisible:false, headerTitle:this.queryParams?.headerTitle, headerSubTitle:this.queryParams?.courseName, headerBackUrl:``})

        this.backQueryParams = { ...this.queryParams,subscriptionId: this.queryParams?.subscriptionId, contentUnitId: this.queryParams?.contentUnitId, menuHierarchy:"7",courseName: this.queryParams?.courseName,buttonFocusId:this.queryParams?.buttonFocusId};
        of('Observable To Update HTML').pipe(delay(500)).subscribe(() => {  
          const buttonToFocus = document.getElementById('exitEPCActivity');
          if (buttonToFocus) {
            buttonToFocus.focus();
          };
        })
        this._cd.detectChanges();
      }
    });
    this.toggleshowHide();

    this.router.events.pipe(takeWhile(() => this.subscriptionActive)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
        this.eventData = event;
      }
    });
    this.routerBackSubscription = this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.headerUrlCall(events);
      });
    this.languageExtService.languageLoadNotifier.subscribe((isLoaded => {
      if (this.data && this.data.languageJson) {
        this.headerLabel = this.data.languageJson?.login?.header_link?.download_link;
        this.languageDropdownLabel = this.data.languageJson?.login?.language_dropdown_label;
        this.toggleText=this.data?.languageJson?.bookmarks?.showZoomArea;
        this.zoomAreaOnLabel = this.data?.languageJson?.bookviewer?.show_zoom_area?.show_zoom_area_on;
        this.zoomAreaOffLabel = this.data?.languageJson?.bookviewer?.show_zoom_area?.show_zoom_area_off;
        if(this.data.isfullScreen) {
          this.screenLabelTooltip = this.data?.languageJson?.bookviewer?.switchMode?.exitFullScreenTooltip;
          this.screenLabel = this.data?.languageJson?.bookviewer?.switchMode?.classicScreenText;
        } else {
          this.screenLabel = this.data?.languageJson?.bookviewer?.switchMode?.fullScreenText;
          this.screenLabelTooltip = this.data?.languageJson?.bookviewer?.switchMode?.fullScreenTooltip;
        }
        if (
          this.data?.bookNavButtonConfig &&
          this.data?.bookNavButtonConfig?.previous?.label &&
          this.data?.bookNavButtonConfig?.next?.label
        ) {
          this.data.bookNavButtonConfig.previous.label =
            this.data?.languageJson?.bookviewer?.icons?.previous;
          this.data.bookNavButtonConfig.next.label =
            this.data?.languageJson?.bookviewer?.icons?.next;
        }
          timer(100).pipe(first()).subscribe(() => {
            this.languageOptions =  this.data?.languageJson?.languageOptions;
            this.selectedOptions = this.languageOptions[0];
            this.data.languageOptions =  this.languageOptions;
            this.cascadeMenuList = [
              [
                { id: 'Languages', name: this.data?.languageJson?.login?.header_link?.languages_label, icon: 'icon-chevron-right', isContainSubMenu: true },
                { id: 'Download', name: this.data?.languageJson?.login?.header_link?.download_link, url: './download' }
          
              ],
              this.data.languageOptions
            ];
             if(GlobalSettings.isNative) {
              this.cascadeMenuList = this.cascadeMenuList.map(menuGroup => {
                  return menuGroup.filter(item => item.id !== 'Download');
              });
            }
            if(!this.isNewlangunageDownload && this.languageOptions){
              this.utilityService.checkInternetConnectivity().pipe(first()).subscribe((isInternetAvailable) => {
                if (isInternetAvailable) {
                  this.commonService.loadNewLanguagesInDevice(this.data?.languageJson?.languageOptions ?? [])
                  this.isNewlangunageDownload = true;
                }
            });
            }
            this.setLanguageSelected() ;
            this._cd.detectChanges();
        });
        };  
        this.setHeader();
        this._cd.detectChanges();
    }));

    this.getOpenDialogParam();
  }

  headerUrlCall(events: any) {
    if (events.length) {
      this.ngZone.run(() => {
        const secondEventUrl = events[1].url;
        if (secondEventUrl?.includes('/bookviewer/') || secondEventUrl?.includes('/bookviewerv/')) {
          this.isBookviewer = true;
        } 
        const globalBackUrl: string = events[0].urlAfterRedirects;
        if (!globalBackUrl?.includes('school-work/progress') || !globalBackUrl?.includes('school-work/grading')) {
          return;
        } 
        if (globalBackUrl.includes('course-details') || globalBackUrl.includes('school-work/calendar')) {
          this.data.globalBackUrl = '/courses/courselist';
        } else {
          this.data.globalBackUrl = globalBackUrl;
        } 
        const emitValue = (globalBackUrl.includes('courselist') && secondEventUrl.includes('courselist')) ? '' : globalBackUrl;
        this.ngZone.run(() => this.data.globalHeaderData.emit({headerLogoVisible:false, headerTitle:'', headerSubTitle:'', headerBackUrl: emitValue}));
      });
    }
  }

  handleNavigationEnd(event:any){
    
    if (isPlatformBrowser(this.platformId)) {
      const windowWidth = window?.innerWidth;
      if (windowWidth > 1023) {
        this.headerDesktopView = true;
      } else {
        this.headerDesktopView = false;
      }
    }
    this.setLanguageSelected();
    if (this.activatedRoute.snapshot?.queryParams.lang) {
      let langInUrl: string = this.activatedRoute.snapshot.queryParams.lang;
      this.selectLanguage(langInUrl);
    } else if(GlobalSettings.isBrowser && this.cookieService.get('langKey')){
      this.selectLanguage(this.cookieService.get('langKey'));
    } else {
      this.selectLanguage(GlobalSettings.defultLang);
    }
    if(GlobalSettings.isBrowser && event.url=='/' && this.cookieService.get('langKey')){
      this.router.navigate(['/'], {queryParams: {lang: this.cookieService.get('langKey')}})
    }
    if(GlobalSettings.isNative && event.url=='/'){
      this.router.navigate(['/'], {queryParams: {lang: GlobalSettings.defultLang}})
    }
    this.currentUrl = event.url;
    // added condition to fix logo click issue on logout the application
    if(this.currentUrl !== '/' && (this.data.aceToken?.MEEUserId || this.data.isOfflineUser)){
      this.logoUrl = 'courses/courselist';
      this.logoUrlParams= {"menuHierarchy":1,"ver":'34'}
    } else {
      this.logoUrl = "/";
    }
    let com_name = this.currentUrl.split('/');
    const currentUrlWithoutqueryParam = event.url.split("?")[0];
    this.setHeader();
    if (currentUrlWithoutqueryParam === '/login' || currentUrlWithoutqueryParam === '/' || currentUrlWithoutqueryParam === '/logout' || currentUrlWithoutqueryParam === '' || currentUrlWithoutqueryParam === '/#page__content') {
      this.showGlobalHeader = false;
      this.showHeaderPrelogin = false;
      this.showHeaderDownloadPage = false;
      this.cascadeMenuList[1].map((obj:any)=>{
        obj.url=currentUrlWithoutqueryParam;
        return obj;
      })
    }
    else if (currentUrlWithoutqueryParam === '/download' || currentUrlWithoutqueryParam === '/download#page__content') {
      this.showGlobalHeader = false;
      this.showHeaderPrelogin = false;
      this.showHeaderDownloadPage = true;
      this.cascadeMenuList[1].map((obj:any)=>{
        obj.url=currentUrlWithoutqueryParam;
        return obj;
      })
      this._cd.detectChanges();
    }
    else {
      this.showGlobalHeader = true;
    }
    
    if (this.currentUrl.includes('returnPath') || this.hideHeaderMobileDropDown(currentUrlWithoutqueryParam)) {
      this.headerMobileDropDown = false;
      this._cd.detectChanges();
    } else {
      this.headerMobileDropDown = true;
      if (this.currentUrl.indexOf('/course-builder/global-system-error') > -1 || this.currentUrl.indexOf('/homework-assessment-student/global-system-error') > -1) {
        this.hideCompleteHeader = false;
        this.headerMobileDropDown = false;
        this.data.globalHeaderData.emit({ headerLogoVisible: true, headerTitle: this.data?.languageJson?.login.account_welcome_text, headerSubTitle: '' })
      }
      this._cd.detectChanges();
    }
    // hide header logo on course details page
    if (com_name[2] && com_name[2] === 'course-details' || this.currentUrl.includes("/school-work") || this.currentUrl.includes('/course-builder/student-dashboard/view-details') ||
      (this.currentUrl.includes('/homework-assessment-student/dashboard') && this.queryParams['redirectStudentUrl'] === 'true')) {
      this.logoShowHide = true;
    } else {
      this.logoShowHide = false;
    }
    if (this.currentUrl.includes("/courses/courselist") || this.currentUrl.includes("/settings/class-management") || this.currentUrl.includes("/notifications") || this.currentUrl.includes("/school-work/grading") || this.currentUrl.includes('/school-work/calendar') ||this.currentUrl.includes("/school-work/progress") || this.currentUrl.includes("/school-work/progress/classes") ||  this.currentUrl.includes("/school-work/progress/students")) {
      this.isInstitutionLogo = this.data.isInstitutionLogo;
      this.institutionLogo = this.data.institutionLogo;
      this.logoShowHide = false;
    }
    if (this.currentUrl.includes("/school-work/progress/search-student/") || this.currentUrl.includes("/courses/course-details") || this.currentUrl.includes("/school-work/grading/student-list") || this.currentUrl.includes("/school-work/grading/assessment-student-list") || this.currentUrl.includes("/school-work/progress/coursework-details") || this.currentUrl.includes("/school-work/progress/student-coursework-details") || this.currentUrl.includes("/school-work/progress/attempt-history") || this.currentUrl.includes("/school-work/progress/teacher-student-details") || this.currentUrl.includes("/school-work/grading/homework-student-list") || this.currentUrl.includes("/school-work/progress/assessment-details")) {
      this.isInstitutionLogo = this.data.isInstitutionLogo;
      this.institutionLogo = this.data.institutionLogo;
      this.logoShowHide = true;
    }

    if(this.currentUrl.includes('/school-work/calendar/add-event') || this.currentUrl.includes('/school-work/progress/search-student/dashboard') || this.currentUrl.includes('/school-work/calendar/calendar-event-detail') || this.currentUrl.includes("/school-work/calendar/add-attendees") || this.currentUrl.includes("/school-work/calendar/review-attendees")) {
      this.isInstitutionLogo = false;
      this.logoShowHide = true;
    }
    if(!this.data.institutionLogo){
        this.isInstitutionLogo = false;
    }
    if (this.currentUrl.includes("/school-work")) {
      if (this.currentUrl.split('?', 1)[0] === '/school-work/calendar/calendar-event-detail') {
        this.hideCompleteHeader = true;
      } else {
        this.hideCompleteHeader = false;
      }
    }
    //Don't show header on view student
    if (this.currentUrl.indexOf('viewStudents') > 0) {
      this.hideCompleteHeader = true;
      return;
    } else {
      this.hideCompleteHeader = false;
    }
    //Don't show header on builder
    if (this.currentUrl.indexOf('builder') > 0) {
      if (this.currentUrl.includes("/school-work")) {
        this.hideCompleteHeader = false;
      } else {
        this.hideCompleteHeader = true;
      }
    } else {
      this.hideCompleteHeader = false;
    }
    // show  header on myactivity page only
    if (this.currentUrl.indexOf('/builder/myactivity') > -1) {
      this.hideCompleteHeader = false;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart && event.url.indexOf('/builder/myactivity') > -1) {
          if (!event.url.includes('&isShowHide') && !event.url.includes('isOpen=false')) {
            this.data.isLoadingLgoin.next(true);
          } else if (event.url.includes('&courseDropdownChanged=true')) {
            this.data.isLoadingLgoin.next(true);
          }
        } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
          if (event.url.indexOf('/builder/myactivity') > -1) {
            this.data.isLoading.next(false);
            of('delay for my activity card').pipe(delay(4000)).subscribe(() => {
              this.data.isLoadingLgoin.next(false);
            });
          }
        }
      });
    }
    // show  header on cb-myactivities page only
    if (this.currentUrl.indexOf('/course-builder/cb-myactivities') > -1) {
      this.hideCompleteHeader = false;
    }
    // show  header on myquestion page only
    if (this.currentUrl.indexOf('/builder/myquestion') > -1) {
      this.hideCompleteHeader = false;
    }
    // show  header on course-builder dashboard
    if (this.currentUrl.indexOf('/course-builder/dashboard') > -1) {
      this.hideCompleteHeader = false;
    }

     // show  header on course-builder dashboard
     if (this.currentUrl.indexOf('/course-builder/student-dashboard') > -1) {
      this.hideCompleteHeader = false;
    }

    // show  header on course-builder dashboard
    if (this.currentUrl.indexOf('/course-builder/global-system-error') > -1) {
      this.hideCompleteHeader = false;
    }

    // show  header on ereader or Bookviewer page only
    if (this.currentUrl.indexOf('/bookviewer/') > -1 || this.currentUrl.indexOf('/bookviewerv/') > -1) {
      this.isBookviewer = true;
      this.commonService.setUpdate(true);
    }
    else {
      this.isBookviewer = false;
      this.commonService.setUpdate(false);
    }
    if (this.currentUrl.indexOf('/homework-assessment/dashboard/homework/school/schoolhomework/courses') > -1) {
      this.backButtonDisabled = true;
    }
    else {
      this.backButtonDisabled = false;
    }
    if (this.currentUrl.indexOf('/homework-assessment/task-listing') > -1) {
      this.isTaskListingPage = true;
    } else {
      this.isTaskListingPage = false;
    }
    if (this.currentUrl.includes('/homework-assessment/selectactivity')) {
      this.selectActivityPage = true;
    } else {
      this.selectActivityPage = false;
    }
    if (this.currentUrl.includes('/extra-practices/dashboard')) {
      this.isExtraPracticePage = true;
    } else {
      this.isExtraPracticePage = false;
    }

    if (this.currentUrl.includes('/challenge/dashboard')) {
      this.isChallengePage = true;
    } else {
      this.isChallengePage = false;
    }
    if (this.currentUrl.includes('/settings/languageSwitcher')) {
      this.isLanguageSwitcherPage = true;
      this.isInstitutionLogo = this.data.isInstitutionLogo;
      this.institutionLogo = this.data.institutionLogo;
    } else {
      this.isLanguageSwitcherPage = false;
    }
    if (this.currentUrl.includes('/grading/grading-paper-assessment')) {
      this.isGradingPaperAssessmentPage = true;
    } else {
      this.isGradingPaperAssessmentPage = false;
    }

    if (this.currentUrl.includes('/offline-bookviewer/add-courses') || this.currentUrl.includes('/offline-bookviewer/add-access-period') || this.currentUrl.includes('/offline-bookviewer/review-courses')) {
      this.isOfflineBookViewerPage = true;
    } else {
      this.isOfflineBookViewerPage = false;
    }

    if (isPlatformBrowser(this.platformId)) {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        this.isShowMenubar = true;
        this.openMenu();
      }
    }

    this.data.isSessionLimitReached.pipe(takeUntil(this.destroySubscription)).subscribe((res: boolean) => {
      if (res) {
        this.hideCompleteHeader = res;
      } else if(!this.currentUrl.includes('/builder') && (!this.currentUrl.includes('/course-builder') && this.currentUrl.includes('/course-builder/dashboard'))) {
        this.hideCompleteHeader = false;
      }
    })
    this._cd.detectChanges();
  }
  hideHeaderMobileDropDown(currentUrlWithoutqueryParam: any) {
    let hideDropDown = false;
    if (GlobalSettings.isNative && (currentUrlWithoutqueryParam === '/login' || currentUrlWithoutqueryParam === '/' || currentUrlWithoutqueryParam === '/logout' || currentUrlWithoutqueryParam === '' || currentUrlWithoutqueryParam === '/#page__content')) {
      if (currentUrlWithoutqueryParam === '/' || currentUrlWithoutqueryParam === '/logout') {
        hideDropDown = false;
      } else {
        hideDropDown = true;
      }
    }
    return hideDropDown;
  }
  getOpenDialogParam(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroySubscription)).subscribe((res: any) => {
      this.queryParams = res;
      if(this.queryParams['currentPage'] && this.queryParams['totalItems']) {
        this.selectQuestionsQueryParams.currentPage =this.queryParams.currentPage;
        this.selectQuestionsQueryParams.totalItems =this.queryParams.totalItems;
      }
      if (this.queryParams['bookself']) {
        this.ngZone.run(() => {
          this.router.navigate([`/courses/courselist`]);
          this._cd.detectChanges();
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    if (event.target.innerWidth > 1023 || event.target.innerWidth==0) {
      this.headerDesktopView = true;
      this.isNoficationDropDown = true;
      this.isMobileBrowser = false;
    } else {
      this.headerDesktopView = false;
    }
  }

  setLanguageSelected() {
    this.selectedLanguage = this.activatedRoute.snapshot?.queryParams?.lang;
    if (this.selectedLanguage) {
      this.addIconCall();
      this.languageOptions.forEach((lang: DropdownOption) => {
        if (lang.value === this.selectedLanguage) {
          lang.active = true;
          lang.icon = 'icon-check';
          this.selectedOptions = lang;
        } else {
          lang.active = false;
          lang.icon = '';
        }
      });
    } else {
      this.cascadeMenuList?.forEach((parentObj: any) => {
        parentObj.forEach((obj: any) => {
          if (obj?.trailingIcon) {
            obj.trailingIcon = "";
          } else if (obj?.queryParams?.lang === this.cookieService.get('langKey')) {
            obj.active=true;
            obj.trailingIcon = "icon-check";
          }
        });
      });
      this.data.isShowLanguageMenuBar.emit("languagemenuno");
    }
    this.isShowlanguageMenubar = false;
  }

  addIconCall() {
    this.cascadeMenuList?.forEach((parentObj: any) => {
      parentObj.forEach((obj: any) => {
        if (obj?.queryParams?.lang === this.activatedRoute.snapshot?.queryParams?.lang) {
          obj.trailingIcon = "icon-check";
        } else {
          obj.trailingIcon = "";
        }
      });
    });
  }

  onClickCascadeClose() {
    this.isShowlanguageMenubar = false;
    this.cascadeMenuList?.forEach((parentObj: any) => {
      parentObj.forEach((obj: any) => obj.active = false);
    });
  }

  onClickCascadeBack(index: number) {
    const windowWidth = window.innerWidth;
    if (windowWidth < smallScreenSize.smallScreenSizeWidth) {
      this.cascadeMenus.splice(index, 1);
      if (this.cascadeMenus.length > 0) {
        this.cascadeMenus[this.cascadeMenus.length - 1].isVisible = true;
      }
    } else {
      this.cascadeMenus.splice(this.cascadeMenus.length - 1, 1);
    }
    if (this.cascadeMenus.length === 0) {
      this.onClickCascadeClose();
    }
  }

  triggerGAForDownload() {
    this.gtmService.advanceTracking(Settings,GTMEvents.ButtonClick,{button_name:'Download app', click_text: this.data.languageJson?.login?.header_link?.download_link})
  }

  onClickCascadeMenu(menu: any, index: number) {
    if(menu.id === 'Download'){
      this.gtmService.advanceTracking(Settings,GTMEvents.ButtonClick,{button_name:'Download app', click_text: this.data.languageJson?.login?.header_link?.download_link})
    }
    if (menu.isContainSubMenu) {
        this.languageExtService.getLanguageKey('langKey').pipe(takeWhile(()=>this.subscriptionActive)).subscribe((defaultLangKeyResponse: any) => {
            this.cascadeMenus.push({
              name: this.backText,
              isVisible: true,
              menus: this.cascadeMenuList[index + 1].map((obj:any)=>{
                if(obj.value===defaultLangKeyResponse){
                  obj.active=true;
                  obj.trailingIcon = "icon-check";
                }
                return obj;
              })
            });
            this._cd.detectChanges();
        })
    }
  }

  openHeaderList() {
        const currentUrlWithoutqueryParam = this.currentUrl.split("?")[0];

    if (!this.isShowlanguageMenubar) {
      if(this.cascadeMenus.length>1){
        this.cascadeMenus.splice(1,this.cascadeMenus.length-1);
      }
      this.isShowlanguageMenubar = true;
      this.data.isShowLanguageMenuBar.emit("languagemenu");
      if (currentUrlWithoutqueryParam === '/login' || currentUrlWithoutqueryParam === '/' || currentUrlWithoutqueryParam === '/logout' || currentUrlWithoutqueryParam === '' || currentUrlWithoutqueryParam === '/#page__content') {
        this.cascadeMenus[0].menus=this.cascadeMenuList[0];
      }
      else if (currentUrlWithoutqueryParam === '/download' || currentUrlWithoutqueryParam === '/download#page__content') {
        this.cascadeMenus[0].menus= this.cascadeMenuList[0].map((obj: { id: string; }) =>{
          if(obj.id !== 'Download') return obj;
          return null;
         }).filter((obj:any)=>obj);
      }
    } else {
      this.isShowlanguageMenubar = false;
      this.data.isShowLanguageMenuBar.emit("languagemenunot");
    }
    this._cd.detectChanges();
  }
  selectLanguage(lang: string) {
    let langInUrl = lang;
    this.languageOptions.forEach((langValue: DropdownOption) => {
      if (langValue.value === langInUrl) {
        langValue.active = true;
        langValue.icon = 'icon-check';
        this.selectedOptions = langValue;
      } else {
        this.isShowlanguageMenubar = false;
        this.data.isShowLanguageMenuBar.emit("languagemenunot");
        langValue.active = false;
        langValue.icon = '';
      }
    });
  }
  ngOnInit(): void {
    if(GlobalSettings.isNative){
      const indexToRemove = this.cascadeMenuList[0].findIndex((obj: { id: string; }) => obj.id === 'Download');
      if (indexToRemove !== -1) {
        this.cascadeMenuList[0].splice(indexToRemove, 1);
      }
    }
    this.cascadeMenus.push({
      name: "",
      isVisible: true,
      menus: this.cascadeMenuList[0]
    });
    this.isFrameNavigationOn = this.data.fullScreenHotspotNavigationState.get("showFrameNavigation");
    this.deviceViewCheck();
    this.hideHeaderFooterCall();
    if (!GlobalSettings.isBrowser) {
      this.disableSearchOfflineDevice();
    }
    this.subscribeCanvasEnabled();
    this.subscribeCanvasDataModified();
    this.subscribeShowConfirmationDialog();
    this._cd.detectChanges();
    this.showPreDefineCall();
    this.isNodeModeOnCall();
    this.canvasEnableCall();
    this.globaleMenuCall();
    this.updateNavigationButtons()
    this.updateHotspotNavigationButtons();
  if(isPlatformBrowser(this.platformId) && GlobalSettings.isBrowser){
    this.closeButton()
  }
  }

  subscribeCanvasEnabled() {
    this.data.canvasEnabled.subscribe((toggleStatus: boolean) => {
      this.isCanvasEnabled = toggleStatus;
    });
  }

  globaleMenuCall() {
    const getEle:any = document.getElementById('hamburgerIcon');
    const windowWidth = window.innerWidth;
    if(getEle){
      if(GlobalSettings.isNative){  
        getEle.style.visibility = 'visible';    
      } else {
        getEle.style.visibility = 'hidden';
      }
    }
    this.isNotesModeOnSubscription = this.data.isShowGlobalMenu.subscribe((value: any) => {
      if (value === "login" && ((windowWidth > smallScreenSize.smallScreenSizeWidth) || GlobalSettings.isDesktop)) {
        this.isNoficationDropDown = true;
        this.hideHamMenu = false;
        this.isMobileBrowser = false;
      } else if (value === "login" && (windowWidth < smallScreenSize.smallScreenSizeWidth)) {
        this.isNoficationDropDown = false;
        this.hideHamMenu = false;
        if (!GlobalSettings.isNative) {
          this.isMobileBrowser = true;
        }
        else {
          this.isMobileBrowser = false;
          this.isNoficationDropDown = true;
        }
      } else {
        this.hideHamMenu = true;
        this.isNoficationDropDown = false;
      }
      this._cd.detectChanges();
    })
  }

  canvasEnableCall() {
    this.canvasEnabledSubscription = this.data.canvasEnabled.subscribe((toggleStatus: boolean) => {
      if (this.data.toggleStatus === 0) {
        if (toggleStatus) {
          this.data.toggleStatus = 1;
          this.data.showPredefinedArea.emit(false);
          this.data.framedZoomedOut = false;
          this.hotspotService.doGreyToggleButton();
        }
      } else {
        if (toggleStatus === false) {
          this.data.toggleStatus = 0;
          this.data.framedZoomedOut = true;
          this.hotspotService.doGreyToggleButton();
        }
      }
    });
  }

  isNodeModeOnCall() {
    this.isNotesModeOnSubscription = this.utilityService.isNotesModeOn.subscribe((value) => {
      if (value) {
        this.data.showPredefinedArea.emit(false);
        this.data.framedZoomedOut = false;
        this.hotspotService.doGreyToggleButton();
        this.data.externalZoomout = true;
        $('.floating-div.selectedZoomTarget')?.trigger('click');
      } else {
        if (this.data.lastZonalOperation === LastZonalOperationState.MY_NOTES || this.data.lastZonalOperation === LastZonalOperationState.CANVAS || this.data.lastZonalOperation === LastZonalOperationState.ZOOM_CONTROLLER) {
          this.data.framedZoomedOut = true;
          this.data.externalZoomout = true;
          this.hotspotService.doGreyToggleButton();
        }
      }
      if (this.data.lastZonalOperation === LastZonalOperationState.MY_NOTES || this.data.lastZonalOperation === LastZonalOperationState.CANVAS) {
        this.data.isNoteEnabled = !!value;
      }
    });
  }

  showPreDefineCall() {
    this.data.showPredefinedArea.subscribe(
      (toggleStatus) => {
        if (toggleStatus) {
          this.toggleNgModel = true;
        } else {
          this.toggleNgModel = false;
        }
        this.data.zoomToggleStatus = this.toggleNgModel;
        this._cd.detectChanges();
      }
    );
  }

  hideHeaderFooterCall() {
    this.data.hideheaderFooter.subscribe(hideRes => {
      if (hideRes) {
        this.hideCompleteHeader = hideRes;
      }
      else {
        this.hideCompleteHeader = false;
      }
      this._cd.detectChanges();
    });
  }

  deviceViewCheck() {
    if ((GlobalSettings.isNative || GlobalSettings.isBrowser) && this.commonService?.getDeviceType() === getdeviceType.tablet) {
      this.isBrowserOrDesktop = false;
      this.isSearch = true;
      this.data.isMobileView = false;
      this._cd.detectChanges();
    } else if (GlobalSettings.isDesktop || (GlobalSettings.isBrowser && this.commonService?.getDeviceType() === getdeviceType.desktop)) {
      this.headerDesktopView = true;
      this.isBrowserOrDesktop = true;
      this.isSearch = true;
      this.data.isMobileView = false;
    }
    else {
      this.headerDesktopView = false;
      this.isSearch = false;
      this.isBrowserOrDesktop = false;
      this.data.isMobileView = true;
    }
  }

  subscribeCanvasDataModified() {
    this.data.canvasDataModified.subscribe((isCanvasDataModified: boolean) => {
      this.isCanvasDataModified = isCanvasDataModified;
    });
  } 
  
  openMenu() {
    const logoIcon = this.elementRef.nativeElement.querySelector('.c-headers__logo-link');
    if (!this.isShowMenubar && !this.isShowCascadebar) {
      this.isShowMenubar = true;
      this.data.loadGlobalNavidation.emit("hamburger");
    } else {
      this.isShowMenubar = false;
      this.isShowCascadebar = false;
      this.data.loadGlobalNavidation.emit("closeHamburger");
      this.title= this.tempTitle;
       this.subTitle=this.tempSubTitle;
      if(logoIcon){logoIcon.style.display = '';}
    }
    this._cd.detectChanges();
  }
  subscribeShowConfirmationDialog() {
    this.data.showConfirmationDialog.subscribe((data: any) => {
      if (!data.visible && data.event === 'exitBook') {
        this.data.canvasDataModified.emit(false);
        this.backToUrl();
      }
    });
  }

  toggleshowHide() {
    this.showtogglebutton = false;
    this.data.showToggle.subscribe((value: any) => {
      const windowWidth = window.innerWidth;
      if (value && !GlobalSettings.isNative && windowWidth > smallScreenSize.smallScreenSizeWidth && this.commonService.getDeviceType() != getdeviceType.tablet) {
        this.showtogglebutton = this.hotspotService.showhideToggleDataCheck();
        this._cd.detectChanges();
      }
    });
  }

  /*disableSearchOfflineDevice: This function will disbale seaech button  for offline dekstop mode*/
  disableSearchOfflineDevice() {
    this.commonService.displayReaderPage$.subscribe(status => {
      if (!status) {
        this.disableSearchButton = true;
      } else {
        this.disableSearchButton = false;
      }
    });
  }


  public closeButton(): void {
    this.data.isQuickLinkTrigger.pipe(
      switchMap(trigger => {
        if (trigger) {
          return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          );
        } else {
          return EMPTY;
        }
      })
    ).subscribe(() => {
      this.backToUrl();
      this.data.isQuickLinkTrigger.next(false);
    });
  }

  backToUrl() {
    this.gtmService.advanceTracking(BookInteractions, GTMEvents.BookClosed,{
      course_name: this.data?.selectedCourse?.courseName,
      course_level: this.data?.selectedCourse?.courseLevel,
      book_name: this.data?.selectedBook?.bookName,
      page_number: this.data?.goToPageNmber
    });
    if (!this.subscriptionId) {
      this.subscriptionId = this._cookieService.get('subscriptionId');
    }
    if (this.isCanvasEnabled && this.isCanvasDataModified) {
      let dialogProps: any = this._whiteboardUtilityService.getSaveDialogLabels();
      dialogProps.visible = true;
      dialogProps.event = 'exitBook';
      this.data.showConfirmationDialog.emit(dialogProps);
      this.data.setDataForReturnToQuicklink = [];
      this.data.showBackLessonData = "";
    } else {
      this.courseService.orientationHandlerforalldevice('backEventCalled');
      const bodyElement = document.body;
      bodyElement.classList.remove('c-hot-corners');
      bodyElement.classList.remove('c-hot-corners--bottom-right');
      bodyElement.classList.remove('c-hot-corners--bottom-left');
      this.data.canvasEnabled.emit(false);
      this.gtmService.eventTracking(BookControls, 'Close Book')
      this.ngZone.run(() => this.router.navigate([`/courses/course-details/${this.subscriptionId}`]));
      this.data.setDataForReturnToQuicklink = [];
      this.data.showBackLessonData = "";
      this._cd.detectChanges();
    }
    this.clearTocData();
    this.data.currMobilePage = "";
    this.data.firstTimeRCFcall = true;
    this.data.isQuickLinkOpen = false;
    this.data.setInteractiveElFocus = true;
    this.data.TOCcalled = false;
    this.data.currentZoomLevel = 100;
    this.zoomControlService.setZoomInOut({ inc: 1, reset: 'reset' });
    this.data.lastZonalOperation = LastZonalOperationState.CLOSE_BOOK;
    this.data.showPredefinedArea.next(false);
    this.cookieService.delete('subscriptionId');
    this.data.isQuicklinksDisabledInBrowser=false;
    this.data.rightSidePanelOpen.next(false);
    this.data.activeSessionRight.next("");
    this._cd.detectChanges();
  }

  globalSearch() {
     this.checkInternetConnect = this.utilityService.checkInternetConnectivityFileExist().subscribe(internetStatus => {
      if (internetStatus) {
        this.sidePanelService.isSidePanelOpen.next(this.data.isQuickLinkOpen);
        this.readiumReaderService.currentReader$.next({ booktriggerType: `globalSearch`, data: {}, CUID: this.contentUnitId });
        this.isSearchPanelOpen = !this.searchButtonClick;
        this.readiumReaderService.showSearchPanelFlag.emit(this.isSearchPanelOpen);
        this.readiumReaderService.showGlobalSearch.emit(true);
        this.gtmService.eventTracking(BookControls, SearchModule)
      }
    });
  }

  subscribeToHeaderData() {
    this.subscription.push(this.data.globalHeaderData.subscribe((globalHeaderData: any) => {
      if (globalHeaderData) {
        //initial values
        this.showLogo = false;
        this.title = "";
        this.subTitle = "";
        this.tempTitle = "";
        this.backUrl = "";
        this.tempSubTitle = "";
        // headerLogoVisible condition
        if (globalHeaderData?.headerLogoVisible) {
          this.showLogo = globalHeaderData?.headerLogoVisible;
        }
        // headerTitle condition
        if (globalHeaderData?.headerTitle) {
          this.title = globalHeaderData?.headerTitle;
          this.tempTitle = globalHeaderData?.headerTitle;
          if (this.currentUrl.includes('/myactivity')) {
            this.subTitle = this.data.languageJson?.dashboard?.listingSection?.schoolWorkLabel;
          }
        }
        // headerTitle headerBackUrl
        if (globalHeaderData?.headerBackUrl) {
          this.backUrl = globalHeaderData?.headerBackUrl;
          if (this.currentUrl.includes('/myquestion')) {
            this.backUrl = this.exitEPCUrl;
          } else if (this.currentUrl.includes('/myactivity')) {
            this.backUrl = this.exitEPCUrl;
          }
        }
        // headerTitle headerSubTitle
        if (globalHeaderData?.headerSubTitle) {
          this.subTitle = globalHeaderData?.headerSubTitle;
          this.tempSubTitle = globalHeaderData?.headerSubTitle;
          if (this.currentUrl.includes('/myactivity')) {
            this.subTitle = this.data.languageJson?.dashboard?.listingSection?.schoolWorkLabel;
          }
        }
      }
      this._cd.detectChanges();
    }))
    this.subscription.push(this.data.headerLinks.subscribe((obj: any) => {
      this.links = obj;
      this._cd.detectChanges();
    }));

    this.subscription.push(this.data.headerBackQueryParams.subscribe((queryParams: any) => {
      this.backQueryParams = queryParams;
      this._cd.detectChanges();
    }));
    this.subscription.push(this.data.refreshFirstLoad.subscribe(() => {
      this.deviceViewCheck();
    }));
  }

  setHeader() {
    this.SearchButtonLabel = this.data.languageJson.global?.global_button?.search;
    this.data?.fullScreenHotspotNavigationState?.set("prevlabel", this.data?.languageJson?.bookviewer?.icons?.previousHotspot)
    this.data?.fullScreenHotspotNavigationState?.set("nextLabel", this.data?.languageJson?.bookviewer?.icons?.nextHotspot)
    if (GlobalSettings.isBrowser) {
      this.HeaderLabelForBrowser();
    } else if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
      if (this.currentUrl.indexOf('/extra-practices/dashboard') > -1) {
        this.headerLabel = this.data.languageJson?.extraPracticeChallenge?.launch_page_buttonExit;
      } else if (this.currentUrl.indexOf('/challenge/dashboard') > -1) {
        this.headerLabel = this.data.languageJson?.challenge?.launch_page_buttonExit;
        this.headerLabelChallenge = this.data.languageJson?.challenge?.launch_page_buttonExit;
      } else if (this.currentUrl === '/login' || this.currentUrl === '/' || this.currentUrl === '/logout' || this.currentUrl === '' || this.currentUrl === '/#page__content') {
        this.headerlinks = [];
      }else if (this.currentUrl.includes(GRADING_PAPER_ASSESSMENT_URL)) {
        this.exitButtonLabel = this.data.languageJson?.grading?.exitLabel;
      } else if (this.currentUrl.includes('/offline-bookviewer/add-courses')) {
        this.showHeaderDownloadPage = false;
        this.subTitle = this.data.languageJson?.dashboard?.offlineBookViewer?.offlinePackage;
        this.title = this.data.languageJson?.dashboard?.offlineBookViewer?.step1;
        this.exitButtonLabel = this.data.languageJson?.dashboard?.offlineBookViewer?.exit_label_button;
        this.exitCourseBundlePage = '/offline-bookviewer/add-courses';
      }else if (this.currentUrl.includes('/offline-bookviewer/add-access-period')) {
        this.showHeaderDownloadPage = false;
        this.subTitle = this.data.languageJson?.dashboard?.offlineBookViewer?.offlinePackage;
        this.title = this.data.languageJson?.dashboard?.offlineBookViewer?.step2;
        this.exitButtonLabel = this.data.languageJson?.dashboard?.offlineBookViewer?.exit_label_button;
        this.exitCourseBundlePage = '/offline-bookviewer/add-access-period';
      }
      else if (this.currentUrl.includes('/offline-bookviewer/review-courses')) {
        this.showHeaderDownloadPage = false;
        this.subTitle = this.data.languageJson?.dashboard?.offlineBookViewer?.offlinePackage;
        this.title = this.data.languageJson?.dashboard?.offlineBookViewer?.step3;
        this.exitButtonLabel = this.data.languageJson?.dashboard?.offlineBookViewer?.exit_label_button;
        this.exitCourseBundlePage = '/offline-bookviewer/review-courses';
      } else if(this.currentUrl.includes('/school-work/progress/search-student')) {
        this.title = this.data.languageJson?.progressTracker?.searchStudent?.headerTitle;
      }
    }
  }

  HeaderLabelForBrowser() {
    if (this.currentUrl === '/download' || this.currentUrl === '/download#page__content') {
      this.headerlinks = [];
    } else if (this.currentUrl.indexOf('/extra-practices/dashboard') > -1) {
      this.headerLabel = this.data.languageJson?.extraPracticeChallenge?.launch_page_buttonExit;
    } else if (this.currentUrl.indexOf('/challenge/dashboard') > -1) {
      this.headerLabel = this.data.languageJson?.challenge?.launch_page_buttonExit;
      this.headerLabelChallenge = this.data.languageJson?.challenge?.launch_page_buttonExit;
    } else if(this.currentUrl.includes('/myquestion')){
      this.title = this.data.languageJson?.dashboard?.assessment?.moduleName;
      this.subTitle = this.data?.languageJson?.dashboard?.listingSection?.schoolWorkLabel;
    } else if (this.currentUrl.includes('/myactivity')) {
      this.title = this.data.languageJson?.dashboard?.homework?.moduleName;
      this.subTitle = this.data?.languageJson?.dashboard?.listingSection?.schoolWorkLabel;
    } else if(this.currentUrl.includes('/cb-myactivities')) {
      this.title = this.data?.languageJson?.courseBuilder?.globalHeader;
    } else if (this.currentUrl.includes(GRADING_PAPER_ASSESSMENT_URL)) {
      this.exitButtonLabel = this.data.languageJson?.grading?.exitLabel;
    } else if(this.currentUrl.includes('/school-work/progress/search-student')) {
      this.title = this.data.languageJson?.progressTracker?.searchStudent?.headerTitle;
    }
    else {
      this.headerlinks = [
        { id: 'download', label: this.headerLabel, url: '/download', icon: '', position: '', color: '', queryParams: '' }
      ];
    }
  }

  showHotspot() {
    this.data.showPredefinedArea.next(this.toggleNgModel);
    this.gtmService.advanceTracking(BookInteractions, GTMEvents.BookZoomAreaToggle,{
      course_name: this.data?.selectedCourse?.courseName,
      course_level: this.data?.selectedCourse?.courseLevel,
      book_name: this.data?.selectedBook?.bookName,
      page_number: this.data.goToPageNmber,
      show_zoom_areas: this.toggleNgModel
    });
  }

  clearTocData(): void {
    this.data.componentStoreData = '';
    this.data.unitTOCStoreData = '';
    this.data.lessonTOCStoreData = '';
    this.data.chaptersTOCStoreData = '';
  }

  //method for navigating from next and prev page 
  updateNavigationButtons() {
    this.data.isNavigateActivityHappen$.pipe(
      takeUntil(this.destroySubscription)
    ).subscribe(({ nextPage, prevPage }) => {
      const bookNavConfig = this.data.bookNavButtonConfig;
  
      // Only update and trigger change detection if there's a change
      if (bookNavConfig.next.disabled !== nextPage || bookNavConfig.previous.disabled !== prevPage) {
        bookNavConfig.next.disabled = nextPage;
        bookNavConfig.previous.disabled = prevPage;
        this._cd.detectChanges(); // Trigger change detection only when needed
      }
    });
   }

//method for navigating from hotspot next and prev frame 
  updateHotspotNavigationButtons() {
    combineLatest([
      this.data.showFrameNavigation,
      this.data.showPrevFrameNavButton,
      this.data.showNextFrameNavButton
    ]).pipe(takeUntil(this.destroySubscription)).subscribe(([frameNav, prevFrameNav, nextFrameNav]) => {
      this.isFrameNavigationOn = frameNav;
      this.commonService.updateNavigationState("prevNavFrame", prevFrameNav);
      this.commonService.updateNavigationState("nextNavFrame", nextFrameNav);
      this._cd.detectChanges(); 
    });
  }

  //method for toggling fullscreen and class mode
  public onToggleFullscreen(): void {
    this.commonService.toggleFullscreen(this.renderer);
    let pageContent=document.getElementById('page__content');
    if(pageContent){
      pageContent.click();
    }
    this._cd.detectChanges();
  }

  public fsHotspotNavTrigger(event?: any, eventType?: string) {
    const isKeyValid = event?.code === keyboardEventCode.ENTER || event?.code === keyboardEventCode.SPACE || event?.type==='click';
    if(!isKeyValid) return;
    const navigation = this.data.frameNavMap.get(eventType);
    if (navigation && this.commonService.getNavigationState(navigation.navFrame)) {
      if (!navigation.requiresKey || (navigation.requiresKey && isKeyValid)) {
        event?.preventDefault?.();
        event?.stopPropagation?.();
        this.commonService.handleFrameNavigation(navigation.action, event);
      }
    }
  }

  public setToolTipAndAlertText(isFullscreen: boolean): string {
    const labelElement = document.getElementById('switchMode');
    const { switchMode = {} } = this.data?.languageJson?.bookviewer || {};
    const {exitFullScreenTooltip,fullScreenTooltip,classicScreenAlertText,fullScreenAlertText} = switchMode;
    if (labelElement) {
      labelElement.textContent = isFullscreen ? fullScreenAlertText: classicScreenAlertText;
    }
    this.selectedPlacement = isFullscreen ? 'bottom' : this.selectedPlacement;
    return isFullscreen ? exitFullScreenTooltip : fullScreenTooltip;
  }

  public navigateToNextPage(){
    this.data.isNavigationTrigger.next(PAGE_NAVIGATION_TYPE.NEXT_PAGE);
  }

  public navigateToPreviousPage(){
    this.data.isNavigationTrigger.next(PAGE_NAVIGATION_TYPE.PREVIOUS_PAGE);
  }
  
  eventTrackingviaButton(url) {
    this.triggerGAForDownload();
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription:Subscription) => subscription.unsubscribe());
    if (this.isNotesModeOnSubscription) {
      this.isNotesModeOnSubscription.unsubscribe();
    }
    if (this.canvasEnabledSubscription) {
      this.canvasEnabledSubscription.unsubscribe();
    }
    if (this.routerBackSubscription) {
      this.routerBackSubscription.unsubscribe();
    }
    if (this.checkInternetConnect) {
      this.checkInternetConnect.unsubscribe();
    }
    this.subscriptionActive = false;
    // Ensure proper cleanup when the component is destroyed
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
 